const apiUrl = process.env.REACT_APP_API_URL;

export const getUsers = async () => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/users/getAll`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const getUserbyId = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/users/getUserbyId/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const updateUser = async (obj) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/users/updateUser`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(obj)
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}