import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Form, Modal, Badge, Spinner } from 'react-bootstrap';
import { getSections, updateSectionPosition, updateNullThisPosition } from '../../services/sections';
import { getNewsBySectionIdForEdit, getLastActiveNews } from '../../services/news';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../components/layout/Loading';
import { debounce } from 'lodash';

function ManageSections() {
  const [newsQty, setNewsQty] = useState(20); // Reducimos la carga inicial de noticias
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const [isLoadingMoreNews, setIsLoadingMoreNews] = useState(false);
  const [selectedSection, setSelectedSection] = useState(0);
  const objToInitialize = { title: null, short_description: null, position: 1, id: 0 };
  const [positions, setPositions] = useState(Array(20).fill(objToInitialize));
  const [news, setNews] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(objToInitialize);
  const [show, setShow] = useState(false);
  const [shouldShift, setShouldShift] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleOptionChange = (event) => {
    setShouldShift(event.target.value === "true");
  };

  const handleClose = () => {
    setShow(false);
    setSelectedArticle(objToInitialize);
    setSelectedPosition(null);
  };

  const nullThisPosition = async (position) => {
    const obj = { sectionId: selectedSection, position };
    try {
      await updateNullThisPosition(obj);
      toast.success("Se actualizó correctamente");
      loadNewsBySection(selectedSection);
    } catch {
      toast.error("Error al actualizar la posición");
    }
  };

  const handleRadioChange = (event) => {
    setSelectedPosition(Number(event.target.value));
  };

  const onArticleSelect = (data) => {
    if (selectedSection !== 0) {
      setSelectedPosition(null);
      setSelectedArticle({
        title: data.title,
        short_description: data.short_description,
        position: 0,
        id: data.id
      });
      setShow(true);
    } else {
      toast.error("Es necesario seleccionar la sección");
    }
  };

  const handleSave = async () => {
    if (selectedPosition && selectedSection !== 0 && selectedArticle.id !== 0) {
      const obj = {
        sectionId: selectedSection,
        articleId: selectedArticle.id,
        position: selectedPosition,
        moveNews: shouldShift,
      };

      try {
        let updateSectionResult = await updateSectionPosition(obj);
        console.log("updateSectionResult", updateSectionResult)
        if(updateSectionResult.success)
        {
          toast.success("Se actualizó correctamente");
          await loadNewsBySection(selectedSection);
          const updatedNews = await getLastActiveNews(newsQty);
          setNews(updatedNews);
        }
        else
        {
          toast.warning(updateSectionResult.msg);
        }
        
      } catch (error) {
        toast.error(`Hubo un error al guardar ${error}`);
      }
    } else {
      toast.error("Es necesario completar el formulario");
    }
  };

  const loadMoreNews = debounce(() => {
    setIsLoadingMoreNews(true);
    setNewsQty((prevQty) => prevQty + 10);
  }, 500);

  const loadNewsBySection = async (sectionId) => {
    setIsLoading(true);
    try {
      const data = await getNewsBySectionIdForEdit(sectionId);
      const updatedPositions = Array(20).fill(objToInitialize).map((_, index) => ({
        title: data[index]?.title || null,
        short_description: data[index]?.short_description || null,
        position: index + 1,
        id: data[index]?.id || 0,
      }));
      setPositions(updatedPositions);
    } catch {
      toast.error("Error al cargar las noticias por sección");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSectionChange = (e) => {
    const sectionId = parseInt(e.target.value);
    setSelectedSection(sectionId);
    loadNewsBySection(sectionId);
  };

  useEffect(() => {
    setIsLoadingMoreNews(true);
    getLastActiveNews(newsQty).then((data) => {
      setNews(data);
      setIsLoadingMoreNews(false);
    });
  }, [newsQty]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [sectionsData, newsData] = await Promise.all([getSections(), getLastActiveNews(newsQty)]);
        setSections(sectionsData);
        setNews(newsData);
      } catch {
        toast.error("Error al cargar datos iniciales");
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xl={12}>
            <Form.Group controlId="formRole">
              <Form.Label>Secciones *:</Form.Label>
              <Form.Control
                as="select"
                name="sectionId"
                value={selectedSection}
                onChange={handleSectionChange}
              >
                <option key={0} value={0}>Seleccionar seccion</option>
                {sections.map((section) => (
                  <option key={section.id} value={section.id}>{section.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <Row className="mt-3">
              <Col className="text-center" xl={12}>
                <h4>Noticias</h4>
              </Col>
            </Row>
            {isLoadingNews ? (
              <Loading />
            ) : (
              <Row className="mt-3">
                <Col className="text-center scrollable" xl={12}>
                  {news.map((item) => (
                    <NewFromDb key={item.id} data={item} onArticleSelect={onArticleSelect} />
                  ))}
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col className="text-center" xl={12}>
                <Button onClick={loadMoreNews}>
                  {isLoadingMoreNews ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Cargando...
                    </>
                  ) : (
                    "Cargar más noticias"
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xl={8}>
            {isLoading ? (
              <Row className="mt-4">
                <Col>
                  <Loading />
                </Col>
              </Row>
            ) : (
              <Row className="mt-3">
                {positions.map((position, index) => (
                  <Col xl={4} key={index}>
                    <SectionCard data={position} onClicNullThisPosition={nullThisPosition} />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Configurar posición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{selectedArticle.title}</h4>
          <p>{selectedArticle.short_description}</p>
          <Row>
            <Col xl={6}>
              <Form>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((position) => (
                  <Form.Check
                    key={position}
                    type="radio"
                    id={`position-${position}`}
                    label={`Posición ${position}`}
                    name="positionRadio"
                    value={position}
                    checked={selectedPosition === position}
                    onChange={handleRadioChange}
                  />
                ))}
              </Form>
            </Col>
            <Col xl={6}>
              <Form>
                <Form.Check
                  type="radio"
                  id="replace-position"
                  label="No desplazar noticias"
                  name="positioningOption"
                  value="false"
                  checked={!shouldShift}
                  onChange={handleOptionChange}
                />
                <Form.Check
                  type="radio"
                  id="shift-down"
                  label="Desplazar noticias"
                  name="positioningOption"
                  value="true"
                  checked={shouldShift}
                  onChange={handleOptionChange}
                />
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

function SectionCard({ data, onClicNullThisPosition }) {
  return (
    <Card className="mt-3 mb-3">
      <Card.Header>
        {data.id === 0 ? (
          <Row>
            <Col xl={12}>Posicion {data.position}</Col>
          </Row>
        ) : (
          <Row>
            <Col xl={8}>Posicion {data.position}</Col>
            <Col xl={4} className="text-end">
              <Button variant="light" size="sm" onClick={() => onClicNullThisPosition(data.position)}>
                <i className="bi bi-x-square"></i>
              </Button>
            </Col>
          </Row>
        )}
      </Card.Header>
      <Card.Body>
        <Card.Title>{data.title}</Card.Title>
        <Card.Text>{data.short_description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

function NewFromDb({ data, onArticleSelect }) {
  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <Card.Title>{data.title}</Card.Title>
        <Card.Text>{data.short_description}</Card.Text>
        <Row>
          <Col xl={12} className="text-end">
            <Button size="sm" onClick={() => onArticleSelect(data)}>
              <i className="bi bi-arrow-right-short"></i>
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col className="text-start">
            Categoria: <Badge bg="secondary">{data.news_category.name}{data.subcategory_name ? `/${data.subcategory_name}` : null}</Badge>
          </Col>
        </Row>
        <Row>
          <Col className="text-start">
            Asignado a: {data.sectionNames ? <Badge bg="dark">{data.sectionNames}</Badge> : ""}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

export default ManageSections;