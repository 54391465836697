import {  Table, Card, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import { getLogs } from '../../services/aiNews';
import Loading from '../layout/Loading';

function AiLogsList({ id }) {
    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    useEffect(()=> {
        if(id !== 0)
        {
            getLogs(id).then((data) => {
                setLogs(data);
                setIsLoading(false);
            });
        } 
        else
        {
            setLogs([]);
            setIsLoading(true);
        }       
    },[id])
    return (
        <>
            <Row className='mt-2 bg-dark'>
                <Col className='text-center'>
                    <h4 className='text-white'>Logs</h4>
                </Col>
            </Row>   
            {
                isLoading ? 
                <Loading/> :
                <Row>
                    <Col style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'auto' }}>              
                        <Table striped bordered responsive className='mt-2 mb-2'> 
                                <thead>                          
                                    <tr>                            
                                        <th className='text-center'>Mensaje</th>
                                        <th className='text-center'>Resultado</th>                                        
                                        <th className='text-center'>Error</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                
                                {
                                logs && logs.length > 0 ?
                                logs.map((step, index) => 
                                (
                                    <tr key={index} className={`${step.success ? "" : "table-danger"}`} >                            
                                        <td className={`text-center`}>{step.message}</td>
                                        <td className='text-center'  >{step.success ? <i class="bi bi-check text-success " ></i> : <i class="bi bi-x text-danger" ></i>}</td>                                        
                                        <td className='text-center'>{step.error}</td>
                                    </tr>                         
                                )) :
                                null                            
                                }                                                            
                                </tbody>
                        </Table>                   
                    </Col>
                </Row> 
            }
               
        </>
    )
}

export default AiLogsList;