const apiUrl = process.env.REACT_APP_API_URL;
const SITE_NAME = process.env.SITE_NAME;

export const getSiteName = () => {
    return SITE_NAME;
}

export const signup = async (username, email, password) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/auth/signup`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify({
            username, email, password
        })
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const signin = async (email, password) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/auth/signin`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify({
            email, password
        })
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const requestPasswordReset = async (email) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/auth/requestPasswordReset`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify({
            email
        })
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const validateRequestPasswordReset = async (iv, content) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/auth/validateRequestPasswordReset`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify({
            iv, content
        })
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const updatePassword = async (user_id, password) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/auth/updatePassword`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify({
            user_id, password
        })
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const activateAccount = async (iv, content) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/auth/activateAccount`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify({
            iv, content
        })
    };
    
    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}