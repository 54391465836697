import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { saveCategory, getActiveCategories } from "../../services/categories";
import { saveSubcategory } from "../../services/subcategories";

function AddCategory() {
    const navigate = useNavigate();
    const [allCategories, setAllCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({
        name: "",
        url: "",
        css_class: "",
        is_active: 1,
        created_at: new Date().toISOString().slice(0, 10),
        subcategory_name: "",
        subcategory_css_class: "",
        subcategory_is_active: 0,
        subcategory_category_id: "",
        subcategory_created_at: new Date().toISOString().slice(0, 10),
    });

    const [isCategorySaved, setIsCategorySaved] = useState(false);
    const [isSubcategorySectionEnabled, setIsSubcategorySectionEnabled] = useState(false);

    const validateCategoryForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!form.name.trim()) {
            newErrors.name = "El nombre de la categoría es obligatorio";
            isValid = false;
        }

        if (!form.url.trim()) {
            newErrors.url = "La dirección es obligatoria";
            isValid = false;
        } else if (!form.url.startsWith("/")) {
            newErrors.url = "La dirección debe comenzar con '/'";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const validateSubcategoryForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!form.subcategory_name.trim()) {
            newErrors.subcategory_name = "El nombre de la subcategoría es obligatorio";
            isValid = false;
        }

        if (!form.subcategory_category_id) {
            newErrors.subcategory_category_id = "Debe seleccionar una categoría para la subcategoría";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSaveCategory = (e) => {
        e.preventDefault();

        if (!validateCategoryForm()) {
            return;
        }

        const categoryPayload = {
            name: form.name,
            url: form.url,
            css_class: form.css_class,
            is_active: form.is_active,
            created_at: form.created_at,
        };

        saveCategory({ category: categoryPayload })
            .then((result) => {
                if (result.success) {
                    toast.success("Categoría guardada con éxito");
                    setIsCategorySaved(true);
                    setIsSubcategorySectionEnabled(true);

                    getActiveCategories()
                        .then((categories) => {
                            setAllCategories(categories);
                            setForm((prevForm) => ({
                                ...prevForm,
                                subcategory_category_id: result.data.id,
                            }));
                        })
                        .catch((error) => {
                            console.error("Error al actualizar las categorías:", error);
                            toast.error("Error al actualizar las categorías");
                        });
                } else {
                    toast.error(result.message || "Error al guardar la categoría");
                }
            })
            .catch((error) => {
                console.error("Error al guardar la categoría:", error);
                toast.error("Ocurrió un error al guardar la categoría");
            });
    };

    const handleSaveSubcategory = (e) => {
        e.preventDefault();

        if (!validateSubcategoryForm()) {
            return;
        }

        const subcategoryPayload = {
            name: form.subcategory_name,
            css_class: form.subcategory_css_class,
            is_active: form.subcategory_is_active,
            category_id: form.subcategory_category_id,
            created_at: form.subcategory_created_at,
        };

        saveSubcategory({ subcategory: subcategoryPayload })
            .then((result) => {
                if (result.success) {
                    toast.success("Subcategoría guardada con éxito");
                    setIsSubcategorySectionEnabled(false);
                    setTimeout(() => {
                        navigate("/ManageCategory");
                    }, 5000); // 5000 ms = 5 segundos
                } else {
                    toast.error(result.message || "Error al guardar la subcategoría");
                }
            })
            .catch((error) => {
                console.error("Error al guardar la subcategoría:", error);
                toast.error("Ocurrió un error al guardar la subcategoría");
            });
    };

    useEffect(() => {
        getActiveCategories()
            .then((categories) => setAllCategories(categories))
            .catch((error) => {
                console.error("Error al cargar categorías activas:", error);
                toast.error("Error al cargar las categorías activas");
            });
    }, []);

    const handleCancel = () => {
        navigate("/ManageCategory");
    };

    return (
        <>
            <Container className="mt-3">
                <Form>
                    <Row className="justify-content-center">
                        <Col xl={6}>
                            <h2>Agregar Categoría</h2>
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Nombre de la categoría</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                    disabled={isCategorySaved}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formUrl" className="mb-3">
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="url"
                                    value={form.url}
                                    onChange={handleChange}
                                    isInvalid={!!errors.url}
                                    disabled={isCategorySaved}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.url}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formCssClass" className="mb-3">
                                <Form.Label>Clase</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="css_class"
                                    value={form.css_class}
                                    onChange={handleChange}
                                    disabled={isCategorySaved}
                                />
                            </Form.Group>
                            <Form.Group controlId="formCreatedDate" className="mb-3">
                                <Form.Label>Fecha de Alta</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="created_at"
                                    value={form.created_at}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group controlId="formIsActive" className="mb-3">
                                <Form.Label>¿Activo?</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    label="Activo"
                                    name="is_active"
                                    checked={form.is_active === 1}
                                    onChange={(e) =>
                                        setForm({ ...form, is_active: e.target.checked ? 1 : 0 })
                                    }
                                    disabled={isCategorySaved}
                                />
                            </Form.Group>
                            <Button
                                variant="primary"
                                className="mb-3"
                                onClick={handleSaveCategory}
                                disabled={isCategorySaved} >
                                Guardar Categoría
                            </Button>
                            <hr className="my-4" />
                            <h5>Subcategorías</h5>
                            <fieldset disabled={!isSubcategorySectionEnabled}>
                                <Form.Group controlId="formSubcategoryName" className="mb-3">
                                    <Form.Label>Nombre de la subcategoría</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory_name"
                                        value={form.subcategory_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.subcategory_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subcategory_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryCategory" className="mb-3">
                                    <Form.Label>Categoría a la que pertenece</Form.Label>
                                    <Form.Select
                                        name="subcategory_category_id"
                                        value={form.subcategory_category_id}
                                        onChange={(e) =>
                                            setForm({
                                                ...form,
                                                subcategory_category_id: e.target.value,
                                            })
                                        }
                                        isInvalid={!!errors.subcategory_category_id}
                                    >
                                        <option value="">Seleccione una categoría</option>
                                        {allCategories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subcategory_category_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryCssClass" className="mb-3">
                                    <Form.Label>Clase</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory_css_class"
                                        value={form.subcategory_css_class}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryCreatedDate" className="mb-3">
                                    <Form.Label>Fecha de Alta</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory_created_at"
                                        value={form.subcategory_created_at}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryIsActive" className="mb-3">
                                    <Form.Label>¿Activo?</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Activo"
                                        name="subcategory_is_active"
                                        checked={form.subcategory_is_active === 1}
                                        onChange={(e) =>
                                            setForm({
                                                ...form,
                                                subcategory_is_active: e.target.checked ? 1 : 0,
                                            })
                                        }
                                    />
                                </Form.Group>
                                <div className="d-flex mt-3 gap-2">
                                    <Button
                                        variant="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={handleSaveSubcategory}
                                    >
                                        Guardar Subcategoría
                                    </Button>
                                </div>
                            </fieldset>
                        </Col>
                    </Row>
                </Form>
            </Container >
            <ToastContainer />
        </>
    );
}

export default AddCategory;