import React from "react";
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';


function AiNewsCard({ id, title, description, content, categoryName, onSelect, selected , hasBeenImported, date, onDiscard}) {
    let sanitizedHtml = null;
    if(content)
    {
        sanitizedHtml = sanitizeHtml(content);
    }
    const handleCheckboxChange = () => {
        onSelect(id, title); // Notificamos al padre cuando cambia el estado del checkbox
    };
    
    return (
        <Card className="mt-2 mb-2">
            <Card.Body>
                <Row>
                    <Col md={6} >
                            <Form.Check 
                            type="checkbox" 
                            label="Seleccionar"
                            id={`customCheckbox_${id}`}  
                            checked={selected} 
                            onChange={handleCheckboxChange} 
                        />
                    </Col>
                    <Col md={6} className="text-end">
                        <Button variant="ligth" onClick={() => onDiscard(id) }><i class="bi bi-trash3-fill"></i></Button>
                    </Col>
                </Row>
              
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{description}</Card.Subtitle>
                <Card.Text dangerouslySetInnerHTML={{ __html: sanitizedHtml }} >                    
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
                <Row>
                    <Col className="text-left" md={6}>{categoryName}</Col>
                    <Col className="text-end" md={6}>
                        {
                            hasBeenImported ?
                                <b className="text-danger">Ya importada</b> :
                                <b className="text-success">Nunca importada</b>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={4} >ID:{id}</Col>
                    <Col md={8} className="text-end" >{date}</Col>
                </Row>
            </Card.Footer>
        </Card>
    );
}

export default AiNewsCard;