import React, { useEffect, useState } from "react";
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Container, Row, Col, Button, Table, Badge } from 'react-bootstrap';
import { getNews, deleteNews } from '../../services/news'
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from "../../components/news/ConfirmationModal";

function Home() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [idTodelte, setIdToDelete] = useState(0)
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 10, //default page size
    });

    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState('');
    const totalPages = Math.ceil(data.length / pagination.pageSize);
    const [showModal, setShowModal] = useState(false);
    const redirect = (id) => {
        localStorage.setItem('news_id', id);
        navigate(`/AddAndEditNews`);
    };

    const redirectAddNews = (id) => {
        localStorage.setItem('news_id', 0);
        navigate(`/AddAndEditNews`);
    };

    const onClicDeleteNews = (id) => {
        setIdToDelete(id);
        setShowModal(true)
    }

    const handleDelete = () => {
        if (idTodelte !== 0) {
            deleteNews(idTodelte).then((data) => {
                getNews().then((news) => {
                    setData(news);
                    setShowModal(false);
                    setIdToDelete(0)
                });
            });
        }
    };

    const columns = [
        {
            header: 'Acciones',
            accessorKey: 'id',
            cell: ({ row }) => (
                <Button
                    variant="primary"
                    onClick={() => redirect(row.original.id)}
                >
                    ...
                </Button>
            )
        },
        {
            header: 'Eliminar',
            accessorKey: 'id',
            cell: ({ row }) => (
                <Button
                    variant="danger"
                    onClick={() => onClicDeleteNews(row.original.id)}
                >
                    <i class="bi bi-trash"></i>
                </Button>
            )
        },
        {
            header: 'Id',
            accessorKey: 'id'
        },
        {
            header: 'Archivos',
            accessorKey: 'filesCount',
            cell: ({ row }) => (
                <>
                    <i class="bi bi-file-earmark-binary"></i> {row.original.filesCount}
                </>
            )
        },
        {
            header: 'Título',
            accessorKey: 'title'
        },
        {
            header: 'Título social',
            accessorKey: 'social_title'
        },
        {
            header: 'Sumario',
            accessorKey: 'short_description'
        },
        {
            header: 'Estatus',
            accessorKey: 'status_name',
            cell: ({ row }) => (
                <>
                    <Badge bg={
                        row.original.status_id === (1 || 2 || 5) ? "secondary" :
                            (row.original.status_id === 4 ? "success" : "danger")
                    }>{row.original.status_name}</Badge>
                </>
            )
        },
        {
            header: 'Fecha de creación',
            accessorKey: 'created_at'
        },
        {
            header: 'Última actualización',
            accessorKey: 'updated_at'
        },
        {
            header: 'Creado por',
            accessorKey: 'created_by_signature'
        },
        {
            header: 'Categoría',
            accessorKey: 'category_name'
        },
        {
            header: 'Subcategoría',
            accessorKey: 'subcategory_name'
        }
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            pagination,
            sorting,
            globalFilter: filtering
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering
    });

    useEffect(() => {
        getNews().then((news) => {
            setData(news);
        })
    }, [])
    return (<>
        <Container>
            <Row className="mt-3">
                <Col xs={8} sm={6} md={4} className="mb-2 mb-md-0 text-start">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar"
                        value={filtering}
                        onChange={e => setFiltering(e.target.value)}
                    />
                </Col>
                <Col xs={4} sm={3} md={2} className="mb-2 mb-md-0 text-start">
                    <Button variant="link" onClick={redirectAddNews}>Agregar nueva noticia</Button>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Table className='mt-3' striped bordered hover responsive>
                        <thead>
                            {
                                table.getHeaderGroups().map(headerGroup => (
                                    <tr className='table-dark' key={headerGroup.id} >
                                        {
                                            headerGroup.headers.map(header => (
                                                <th className='text-center'
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {header.column.columnDef.header}
                                                    {
                                                        header.column.getIsSorted() ? (header.column.getIsSorted() == "asc" ? <i class="bi bi-arrow-up-short text-white"></i> : <i class="bi bi-arrow-down-short text-white"></i>) : null
                                                    }
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody>
                            {
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {
                                            row.getVisibleCells().map((cell) => (
                                                <td className='text-center' key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li className={`page-item ${pagination.pageIndex === 0 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.setPageIndex(0)}>&laquo;</a>
                            </li>
                            <li className={`page-item ${pagination.pageIndex === 0 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.previousPage()}>Anterior</a>
                            </li>
                            <li className={`page-item ${pagination.pageIndex === totalPages - 1 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.nextPage()}>Siguiente</a>
                            </li>
                            <li className={`page-item ${pagination.pageIndex === totalPages - 1 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.setPageIndex(totalPages - 1)}>&raquo;</a>
                            </li>
                            <li className='page-item' >
                                <a className="page-link disabled"  >{pagination.pageIndex + 1}/{totalPages}</a>
                            </li>
                        </ul>
                    </nav>
                </Col>
            </Row>
            <ConfirmationModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleConfirm={handleDelete}
            />
        </Container>
    </>);
}

export default Home;