const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const getAllMsg = async () => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/contactus/getAllMsg`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            console.error(`Error ${response.status}: ${response.statusText}`);
            return { result: false };
        }

        return await response.json();
    } catch (error) {
        console.error('Error en fetch:', error);
        return { result: false };
    }
};