import {  Table, Card, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import { getNewArticles } from '../../services/aiNews';
import Loading from '../layout/Loading';
import sanitizeHtml from 'sanitize-html';



function NewArticlesResult({ id }) {
    const [isLoading, setIsLoading] = useState(true);
    const [news, setNews] = useState([]);
    useEffect(()=> {
        if(id !== 0)
        {
            getNewArticles(id).then((data) => {
                setNews(data);
                setIsLoading(false);
            });
        } 
        else
        {
            setNews([]);
            setIsLoading(true);
        }       
    },[id])
    return (
        <>
            <Row className='mt-4 bg-dark'>
                <Col className='text-center'>
                    <h4 className='text-white'>Nuevas noticias ({news.length})</h4>
                </Col>
            </Row>  
            {
                isLoading ? 
                <Loading/> : 
                <Row>
                    <Col style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'auto' }}>
                        <Table striped bordered responsive className='mt-2 mb-2'> 
                            <thead>                          
                                <tr>                            
                                    <th className='text-center'>Titulo</th>
                                    <th className='text-center'>Descripcion corta</th>                                        
                                    <th className='text-center'>Contenido</th>  
                                    <th className='text-center'>Categoria</th>                                        
                                </tr>
                            </thead>
                            <tbody>
                                { news.map((article, index) => 
                                    (
                                    <tr key={index}  >                            
                                        <td className={`text-center align-middle`}>{article.title}</td>
                                        <td className='text-center align-middle'  >{article.description}</td>                                        
                                        <td 
                                                className="text-center" 
                                                dangerouslySetInnerHTML={{ __html: sanitizeHtml(article.content) }}
                                            ></td>
                                        <td className='text-center align-middle'>{article["newsCategories.name"]}</td>
                                    </tr>   
                                    )
                                )}
                            </tbody>
                        </Table>   
                    </Col>
                </Row>   
            }  
        </>
    )
}

export default NewArticlesResult;