import React, { useEffect, useState } from "react";
import { getFilesByNewsId } from "../../services/news";
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import { deleteFileById, updateFileOrder } from '../../services/news';
import {  toast } from 'react-toastify';
import Loading from "../layout/Loading";

function FilesContainerEdit({ news_id, reloadFilesContainer, onUpdateFilesQty }) {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [reloadFiles, setReloadFiles] = useState(false);
    const [filesQty, setFilesQty] = useState(0);
    const deleteFile = (id) => {
        deleteFileById(id).then(() => {
            setReloadFiles(!reloadFiles)
        })
    }

    const handleSelectChange = (e, itemId) => {
        setIsLoading(true);
        const selectedOrder = e.target.value; // Obtiene el orden seleccionado
        updateFileOrder(news_id, itemId, selectedOrder).then(() => {
            toast.success('Se actualizó correctamente');
        }).finally(() => {
            getFilesByNewsId(news_id).then((data) => {
                setFiles(data)
                setFilesQty(data.length);
                setIsLoading(false);
                onUpdateFilesQty(data.length)
            })
        })
    };

    useEffect(() => {
        setIsLoading(true);
        getFilesByNewsId(news_id).then((data) => {
            setFiles(data)
            setFilesQty(data.length);
            setIsLoading(false);
            onUpdateFilesQty(data.length)
        })
    }, [reloadFilesContainer, reloadFiles])

    return (<>
        <Container>
            <Row className="mt-3">
                {
                    isLoading ?
                        <Loading /> :
                        files.length == 0 ?
                            <h5>No hay archivos</h5> :
                            files.map((item, index) => (
                                <Col key={index} xs={12} md={6}>
                                    <Card className="m-1" >
                                        {item.mime_type.includes("image/") ? (
                                            <Card.Img variant="top" src={item.url} />
                                        ) : item.mime_type.includes("video/") ? (
                                            <Card.Body>
                                                <video width="100%" controls>
                                                    <source src={item.url} type={item.mime_type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </Card.Body>
                                        ) : null}
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <Button variant="danger" size="sm" onClick={() => deleteFile(item.id)} >
                                                        Eliminar
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Orden</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={(e) => handleSelectChange(e, item.id)} // Maneja el cambio de selección
                                                        >
                                                            <option key={0} value={0}>Seleccionar orden</option>

                                                            {Array.from({ length: filesQty }, (_, index) => (
                                                                <option key={index} selected={item.order == (index + 1) ? true : false} value={index + 1}>
                                                                    {index + 1}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                }
            </Row>
        </Container>
    </>)
}

export default FilesContainerEdit;