const apiUrl = process.env.REACT_APP_API_URL;

export const getActiveSubcategories = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/subcategories/getActive/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const saveSubcategory = async (obj) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/subcategories/saveSubcategory`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone,
        },
        body: JSON.stringify(obj),
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { success: false, message: "Error al guardar la subcategoría" };
    }
};