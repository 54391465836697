import { Container, Row, Col, Button, Form, Spinner, Alert, Card, FormControl } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getActiveCategories } from '../../services/categories';
import { getActiveSubcategories } from '../../services/subcategories';
import { saveDraftNews, getNewsById, updateNews, uploadFiles } from '../../services/news';
import Loading from '../../components/layout/Loading';
import { getStatuses } from '../../services/statuses'
import FilesContainerEdit from '../../components/news/FilesContainerEdit'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function AddAndEditNews() {
    const navigate = useNavigate();
    const [isUploadingFiles, setIsUploadingFiles] = useState(false);
    const [uploadFilesResult, setUploadFilesResult] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [editorContent, setEditorContent] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [reloadFilesContainer, setReloadFilesContainer] = useState(false);
    const [filesQty, setFilesQty] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    let id = 0;
    if (localStorage.getItem('news_id') == null || localStorage.getItem('news_id') == undefined) {
        id = 0;
    } else {
        id = parseInt(localStorage.getItem('news_id'));
    }
    const saveDraft = () => {
        setIsLoading(true);
        saveDraftNews(form).then((data) => {
            setForm({
                ...form,
                ["id"]: data.id,
            });
            setIsLoading(false);
        })
    }
      const loadDataToForm = (data) => {
        setForm({
            id: id,
            title: data.title,
            social_title: data.social_title,
            short_description: data.short_description,
            description: data.description,
            descriptionJson: data.descriptionJson,
            descriptionHtml: data.descriptionHtml,
            category_id: data.category_id,
            subcategory_id: data.subcategory_id,
            embedded_code: data.embedded_code,
            status_id: data.status_id
        });
        setTimeout(() => {
            setEditorContent(data.descriptionHtml ? data.descriptionHtml : `<p>${data.description}</p>`);
        }, 500);
    }
    const toolbarOptions = [
        ['link'],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean']
    ];

    const [form, setForm] = useState({
        id: id,
        title: '',
        social_title: '',
        short_description: '',
        description: '',
        descriptionJson: '',
        descriptionHtml: '',
        category_id: 0,
        subcategory_id: 0,
        embedded_code: '',
        status_id: 0
    });

    const updateFilesQty = (qty) => {
        setFilesQty(qty);
    };

    const redirectHome = () => {
        navigate('/');
    };

    const handleEditorChange = (content, delta, source, editor) => {
        const plainText = editor.getText();
        setEditorContent(content);
        setForm({
            ...form,
            description: plainText,
            descriptionHtml: content
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;        
       
        if(name == "category_id")
        {
            setForm({
                ...form,
                [name]: value,
                ["subcategory_id"]: 0,
            })
        }
        else
        {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setIsUploadingFiles(true);
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append(`file${i}`, files[i]);
            }

            uploadFiles(data, form.id)
                .then((result) => {
                    setIsUploadingFiles(false);
                    setUploadFilesResult(result.success);
                    setReloadFilesContainer(!reloadFilesContainer);

                    if (result.success) {
                        toast.success("Los archivos se han subido exitosamente.");
                    } else {
                        toast.error("Hubo un problema al subir los archivos.");
                    }
                })
                .catch(() => {
                    setIsUploadingFiles(false);
                    setUploadFilesResult(false);
                    toast.error("Hubo un problema al subir los archivos.");
                });
        }
    };

    const onUpdateNews = () => {
        updateNews(form).then((result) => {
            if (result.success) {
                toast.success("Se actualizó correctamente");
            } else {
                toast.error("Algo salió mal");
            }
        });
    };

    useEffect(() => {
        if (parseInt(form.category_id) !== 0) {
            getActiveSubcategories(parseInt(form.category_id)).then((data) => {
                setSubcategories(data);
            });
        } else {
            setSubcategories([]);
        }
    }, [form.category_id]);

    useEffect(() => {
        getStatuses().then((data) => {
            setStatuses(data);
        });
        getActiveCategories().then((data) => {
            setCategories(data);
        });
        if (id !== 0) {
            getNewsById(id).then((data) => {
                    if (data.subcategory_id !== null && data.subcategory_id !== 0) {
                        getActiveSubcategories(parseInt(form.category_id)).then((data) => {
                            setSubcategories(data);
                        }).finally(() => {
                            loadDataToForm(data);
                        })
                    }
                    else {
                        loadDataToForm(data);
                    }
            });
        }
    }, []);
    return (<>
        <Container className='mb-5'>
            <Row className='justify-content-center mt-3'>
                <Col xl={6}>
                    <Card>
                        <Card.Header>                        
                            {
                                id === 0 ?
                                    <Row >
                                        <Col xl={12}>
                                            Nota 
                                        </Col>
                                    </Row> :
                                    <Row >
                                        <Col xl={10}>
                                            Nota
                                        </Col>
                                        <Col xl={2} className='text-end'>
                                            <Form.Label>Id:{id}</Form.Label>
                                        </Col>
                                    </Row>
                            }
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" id='title' name='title' placeholder='Título' value={form.title} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Título Social</Form.Label>
                                        <Form.Control type="text" id='social_title' name='social_title' placeholder='Título Social' value={form.social_title} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Sumario</Form.Label>
                                        <Form.Control type="text" id='short_description' name='short_description' placeholder='Sumario' value={form.short_description} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <label className="font-weight-bold">Nota:</label>
                                    <ReactQuill
                                        value={editorContent}
                                        onChange={handleEditorChange}
                                        modules={{ toolbar: toolbarOptions }}
                                        theme="snow"
                                        placeholder="Escribe la nota aquí..."
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Código para embebidos</Form.Label>
                                        <Form.Control
                                            as="textarea" // Esto convierte el control en un <textarea>
                                            id='embedded_code'
                                            name='embedded_code'
                                            placeholder='Código para embebidos'
                                            onChange={handleInputChange}
                                            value={form.embedded_code}
                                            rows={5}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={6}>
                                    <Form.Group controlId="formRole">
                                        <Form.Label>Categoría *:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="category_id"
                                            value={form.category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option key={0} value={0}>Seleccionar categoría</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xl={6}>
                                    <Form.Group controlId="formRole">
                                        <Form.Label>Subcategoria *:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="subcategory_id"
                                            value={form.subcategory_id}
                                            onChange={handleInputChange}
                                        >
                                            <option key={0} value={0}>Seleccionar subcategoría</option>
                                            {subcategories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                form.id !== 0 ?
                                <>
                                  
                                    <Row className='mt-3' >
                                        <Col xl={12}>
                                            <Form.Group controlId="formRole">
                                                <Form.Label>Status *:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status_id"
                                                    value={form.status_id}
                                                    onChange={handleInputChange}
                                                >
                                                    {statuses.map((status) => (
                                                        <option key={status.id} value={status.id}>{status.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <b className='text-info'>
                                            <small>
                                            Antes de cambiar el estatus a ‘Online’, asegúrate de que la nota incluya al menos un elemento multimedia como imágenes, videos o códigos embebidos. No es posible actualizar el estatus a ‘Online’ si estos elementos no están presentes.
                                            </small>
                                            </b>
                                        </Col>
                                    </Row>
                                    </>
                                     :
                                    null
                            }
                            {
                                isLoading ?
                                    <Loading /> :
                                    form.id == 0 ?
                                        <Row className=' mt-3'>
                                            <Col xl={12} className='text-left'>
                                                <Button className='m-1' variant='secondary' onClick={redirectHome}>Cancelar</Button>
                                                <Button className='m-1' variant='success' onClick={saveDraft} >Guardar</Button>
                                            </Col>
                                        </Row> :
                                        null
                            }
                            {
                                isLoading ?
                                    <Loading /> :
                                    form.id !== 0 ?
                                        <Row className=' mt-3'>
                                            <Col xl={12} className='text-left'>
                                                <Button className='m-1' variant='secondary' onClick={redirectHome} >Cancelar</Button>
                                                <Button className='m-1' variant='success' onClick={onUpdateNews} >Actualizar</Button>
                                            </Col>
                                        </Row> :
                                        null
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                form.id == 0 ?
                    null :
                    <>
                        <Row className='justify-content-center mt-3'>
                            <Col xl={6}>
                                <Card>
                                    <Card.Header>
                                        Multimedia
                                    </Card.Header>
                                    <Card.Body>
                                    <Form.Group>
                                        <Form.Label>Selecciona archivos</Form.Label>
                                        <Form.Control
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </Form.Group>
                                    {isUploadingFiles && (
                                        <Alert variant="info" className="mt-3">
                                            Subiendo archivos, por favor espera...
                                        </Alert>
                                    )}
                                    {uploadFilesResult === true && (
                                        <Alert variant="success" className="mt-3">
                                            Los archivos se han subido exitosamente.
                                        </Alert>
                                    )}
                                    {uploadFilesResult === false && (
                                        <Alert variant="danger" className="mt-3">
                                            Hubo un error al subir los archivos.
                                        </Alert>
                                    )}
                                    <FilesContainerEdit
                                        news_id={form.id}
                                        reloadFilesContainer={reloadFilesContainer}
                                        onUpdateFilesQty={updateFilesQty}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
            }
        </Container>
        <ToastContainer position="top-center" />
    </>)
}


export default AddAndEditNews;