import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import { getNewArticles } from '../../services/aiNews';
import NewArticlesResult from './NewArticlesResult';
import AiLogsList from './AiLogsList';


function LogViewerModal({ show, handleClose, id }) {
    const [news, setNews] = useState([]);
    useEffect(()=> {
        if(id !== 0)
        {
            getNewArticles(id).then((data) => {
                setNews(data);
            });
        } 
        else
        {
            setNews([]);
        }       
    },[id])
  return (
    <Modal show={show} onHide={handleClose} centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Resumen</Modal.Title>
      </Modal.Header>
      <Modal.Body>        
         <AiLogsList id={id} />
        <NewArticlesResult id={id} />        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>     
      </Modal.Footer>
    </Modal>
  );
};

export default LogViewerModal;