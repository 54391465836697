const apiUrl = process.env.REACT_APP_API_URL;

export const saveDraftNews = async (obj) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/saveDraftNews`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(obj)
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const updateNews = async (obj) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/updateNews`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(obj)
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const uploadFiles = async (files, id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/files/uploadFiles/${id}`;
    const requestOptions = {
        method: 'POST',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: files
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const getFilesByNewsId = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/getFilesByNewsId/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const deleteFileById = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/deleteFileById/${id}`;
    const requestOptions = {
        method: 'DELETE',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const getNews = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/getNews/`;
    const requestOptions = {
        method: 'GET',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        console.log(error)
        return { result: false }
    }
}

export const getNewsById = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/getNewsById/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const updateFileOrder = async (news_id, fileId, order) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/updateFileOrder/${news_id}/${fileId}/${order}`;
    const requestOptions = {
        method: 'PUT',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

export const getLastActiveNews = async (qty) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/getLastActiveNews/${qty}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        console.error('Error al obtener las últimas 30 noticias:', error);
        return [];
    }
};

export const deleteNews = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/deleteNews/${id}`;
    const requestOptions = {
        method: 'DELETE',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };
    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const getNewsBySectionIdForEdit = async (id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/news/getNewsBySectionIdForEdit/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };
    
    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        console.error('Error al obtener las últimas 30 noticias:', error);
        return [];
    }
};