const apiUrl = process.env.REACT_APP_API_URL;

export const getRoles = async () => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/roles/getAll`;
    const requestOptions = {
        method: 'GET',
        headers: {

            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}