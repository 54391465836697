import React, { useState } from 'react';
import { Container, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { signup } from '../../services/auth';
import { ToastContainer, toast } from 'react-toastify';
import './Signup.css';

function Signup() {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    repeatPassword: '',
  });

  const [passwordValidity, setPasswordValidity] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    match: false,
  });

  const validatePassword = (password, repeatPassword) => {
    const length = password.length >= 8;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    const specialChar = /[!@#$%^&*]/.test(password);
    const match = password === repeatPassword;

    setPasswordValidity({
      length,
      uppercase,
      lowercase,
      number,
      specialChar,
      match,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'password' || name === 'repeatPassword') {
      validatePassword(
        name === 'password' ? value : formData.password,
        name === 'repeatPassword' ? value : formData.repeatPassword
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(passwordValidity).every(Boolean)) {
      signup(formData.username, formData.email, formData.password).then((signupResult) => {
        if (signupResult.result) {
          setFormSuccess(true);
        }
        else {
          toast.success(signupResult.msg);
        }
      })
    } else {
      console.log('El formulario tiene errores.');
    }
  };

  return (
    <>
      {
        formSuccess ?
          <Container fluid className="mt-5 justify-content-center align-items-center min-vh-100">
            <div className="login-container">
              <Row className='mt-3'>
                <Col>
                  <h1>ChihuaNews.mx</h1>
                </Col>
              </Row>
              <Row>
                <Col >
                  <h3>
                    Hemos creado exitosamente su cuenta y le hemos enviado un correo electrónico con los detalles para activarla.
                  </h3>
                  <h4>
                    Si no encuentra nuestro correo en su bandeja de entrada, por favor revise su carpeta de correo no deseado, spam o junk.
                  </h4>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <a href='/login'>Inicio de sesión</a>
                </Col>
              </Row>
            </div>
          </Container>
          :
          <Container className="d-flex justify-content-center mt-5 min-vh-100">
            <div className="signup-container">
              <h2 className="text-center mb-4">Registrarse</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUsername" className="mb-3">
                  <Form.Label>Nombre de Usuario</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="bi bi-person-fill"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa tu nombre de usuario"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="bi bi-envelope-fill"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Contraseña</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="bi bi-lock-fill"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Ingresa tu contraseña"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                  <Form.Text className="text-muted">
                    La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y un carácter especial.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formRepeatPassword" className="mb-3">
                  <Form.Label>Repite tu Contraseña</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="bi bi-lock-fill"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Repite tu contraseña"
                      name="repeatPassword"
                      value={formData.repeatPassword}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <ul className="list-unstyled">
                  <li>
                    {passwordValidity.length ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos 8 caracteres
                  </li>
                  <li>
                    {passwordValidity.uppercase ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos una letra mayúscula
                  </li>
                  <li>
                    {passwordValidity.lowercase ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos una letra minúscula
                  </li>
                  <li>
                    {passwordValidity.number ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos un número
                  </li>
                  <li>
                    {passwordValidity.specialChar ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos un carácter especial
                  </li>
                  <li>
                    {passwordValidity.match ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Las contraseñas coinciden
                  </li>
                </ul>
                <Button variant="primary" type="submit" className="w-100">
                  Registrarse
                </Button>
              </Form>
              <Row className='mt-3'>
                <Col>
                  <a href='/login'>Inicio de sesión</a>
                </Col>
              </Row>
            </div>
          </Container>
      }
      <ToastContainer />
    </>
  );
}

export default Signup;