const apiUrl = process.env.REACT_APP_API_URL;
// const secondApiUrl = process.env.REACT_APP_SECOND_API_URL;
export const getAiNews = async(qty, excludeImported = 1, aiCategoryId) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/ainews/getAiNews/${qty}/${excludeImported}/${aiCategoryId}`;       
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };
    
    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

//iphone 12

export const importNews = async (obj) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/ainews/importNews`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(obj)
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}
export const discardNews = async(id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/ainews/discardNews/${id}`;       
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }        
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}



// export const step1 = async() => {
//     const token = sessionStorage.getItem('token');
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;    
//     const url =  `${secondApiUrl}/api/step1`;           
//     const requestOptions = {
//         method: 'GET',
//         headers: { 
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//             'Time-Zone': userTimeZone
//         }
//     };
//     try {
//        const response = await fetch(url, requestOptions);
//        return await response.json(); 
//     } catch (error) {
//         console.log(error)
//         return { result: false }
//     }
// }


// export const step2 = async(id, userInput) => {
//     const token = sessionStorage.getItem('token');
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const url =  `${secondApiUrl}/api/step2`;           
//     const requestOptions = {
//         method: 'POST',
//         headers: { 
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//             'Time-Zone': userTimeZone
//         },
//         body: JSON.stringify({ id, userInput })
//     };
//     try {
//        const response = await fetch(url, requestOptions);
//        return await response.json(); 
//     } catch (error) {
//         console.log(error)
//         return { result: false }
//     }
// }



// export const step3 = async(filteredIds, id) => {
//     const token = sessionStorage.getItem('token');
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const url =  `${secondApiUrl}/api/step3`;           
//     const requestOptions = {
//         method: 'POST',
//         headers: { 
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//             'Time-Zone': userTimeZone
//         },
//         body: JSON.stringify({ filteredIds, tracking_id: id })
//     };
//     try {
//        const response = await fetch(url, requestOptions);
//        return await response.json(); 
//     } catch (error) {
//         console.log(error)
//         return { result: false }
//     }
// }



// export const step4 = async(id) => {
//     const token = sessionStorage.getItem('token');
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const url =  `${secondApiUrl}/api/step4/${id}`;           
//     const requestOptions = {
//         method: 'GET',
//         headers: { 
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//             'Time-Zone': userTimeZone
//         },        
//     };
//     try {
//        const response = await fetch(url, requestOptions);
//        return await response.json(); 
//     } catch (error) {
//         console.log(error)
//         return { result: false }
//     }
// }

export const getLogs = async(id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/ainews/getLogs/${id}`;           
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return { result: false }
    }
}

export const getProcess_trackers = async(qty) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/ainews/getProcess_trackers/${qty}`;           
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return { result: false }
    }
}
export const getNewArticles = async(id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/ainews/getNewArticles/${id}`;           
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return { result: false }
    }
}

// export const noNewArticles = async(id) => {
//     const token = sessionStorage.getItem('token');
   
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const url =  `${secondApiUrl}/api/noNewArticles/${id}`;           
//     const requestOptions = {
//         method: 'GET',
//         headers: { 
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//             'Time-Zone': userTimeZone
//         }
//     };
//     try {
//        const response = await fetch(url, requestOptions);
//        return await response.json(); 
//     } catch (error) {
//         console.log(error)
//         return { result: false }
//     }
// }



export const getAiCategories = async(qty) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/ainews/getAiCategories`;           
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return { result: false }
    }
}