const apiUrl = process.env.REACT_APP_API_URL;

export const getSectionPositions = async (sectionId) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/sections/getSectionPositions/${sectionId}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };

    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error('Error al obtener las posiciones de la sección');
        }
        return await response.json();
    } catch (error) {
        console.error('Error al obtener las posiciones de la sección', error);
        return null;
    }
};

export const getSections = async () => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/sections/getSections`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        }
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
};

export const sendUpdateSectionPositionsRequest = async (positions) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/sections/updateSectionPositions`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(positions)
    };

    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            console.error('Error en la respuesta del servidor:', response.status);
            return { success: false };
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error al enviar la solicitud:', error);
        return { success: false };
    }
};

export const updateSectionPosition = async (data) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/sections/updateSectionPosition`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}

export const updateNullThisPosition = async (data) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${apiUrl}/api/sections/updateNullThisPosition`;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
        },
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return { result: false }
    }
}