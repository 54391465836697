import React, { useEffect, useState } from "react";
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Container, Row, Col, Button, Table, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getCategory } from "../../services/categories";


function UpdateCategoriesName() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 10, //default page size
    });

    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState('');
    const totalPages = Math.ceil(data.length / pagination.pageSize);

    const redirect = (id) => {
        localStorage.setItem('id', id);
        navigate(`/EditCategory`);
    }

    const redirectAddCategory = (id) => {
        localStorage.setItem('id', 0);
        navigate(`/AddCategory`);
    };

    const columns = [
        {
            header: 'Acciones',
            accessorKey: 'id',
            cell: ({ row }) => (
                <Button
                    variant="primary"
                    onClick={() => redirect(row.original.id)}
                >
                    ...
                </Button>
            )
        },
        {
            header: 'Id',
            accessorKey: 'id'
        },
        {
            header: 'Nombre',
            accessorKey: 'name'
        },
        {
            header: 'Activa',
            accessorKey: 'is_active'
        },
        {
            header: 'Dirección',
            accessorKey: 'url'
        },
        {
            header: 'Clase',
            accessorKey: 'css_class'
        },
        {
            header: 'Fecha de creación',
            accessorKey: 'created_at'
        }
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            pagination,
            sorting,
            globalFilter: filtering
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering
    });

    useEffect(() => {
        getCategory().then((category) => {
            setData(category);
        }).catch((error) => {
            console.error('Error al obtener las categorías:', error);
        });
    }, []);

    return (
        <Container>
            <Row className="mt-3">
                <Col xs={12} sm={6} md={4} className="mb-2 mb-md-0 text-start">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar"
                        value={filtering}
                        onChange={e => setFiltering(e.target.value)}
                    />
                </Col>
                <Col xs={4} sm={3} md={2} className="mb-2 mb-md-0 text-start">
                    <Button variant="link" onClick={redirectAddCategory}>Agregar Categoría</Button>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Table className='mt-3' striped bordered hover responsive>
                        <thead>
                            {
                                table.getHeaderGroups().map(headerGroup => (
                                    <tr className='table-dark' key={headerGroup.id} >
                                        {
                                            headerGroup.headers.map(header => (
                                                <th className='text-center'
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {header.column.columnDef.header}
                                                    {
                                                        header.column.getIsSorted() ?
                                                            (header.column.getIsSorted() === "asc" ?
                                                                <i className="bi bi-arrow-up-short text-white"></i> :
                                                                <i className="bi bi-arrow-down-short text-white"></i>) : null
                                                    }
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody>
                            {
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {
                                            row.getVisibleCells().map((cell) => (
                                                <td className='text-center' key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className={`page-item ${pagination.pageIndex === 0 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.setPageIndex(0)}>&laquo;</a>
                            </li>
                            <li className={`page-item ${pagination.pageIndex === 0 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.previousPage()}>Anterior</a>
                            </li>
                            <li className={`page-item ${pagination.pageIndex === totalPages - 1 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.nextPage()}>Siguiente</a>
                            </li>
                            <li className={`page-item ${pagination.pageIndex === totalPages - 1 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => table.setPageIndex(totalPages - 1)}>&raquo;</a>
                            </li>
                            <li className='page-item' >
                                <a className="page-link disabled"  >{pagination.pageIndex + 1}/{totalPages}</a>
                            </li>
                        </ul>
                    </nav>
                </Col>
            </Row>
        </Container>
    );
}

export default UpdateCategoriesName;