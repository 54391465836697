import React, { useState, useEffect } from 'react';
import { Container, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './Login.css';
import { validateRequestPasswordReset, updatePassword } from '../../services/auth';

function PasswordReset() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const iv = queryParams.get('iv');
  const content = queryParams.get('content');
  const [user_id, setUser_id] = useState(0);
  const [isValidLink, setIsValidLink] = useState(null); // Cambiado a null para manejar el estado de carga
  const [formSuccess, setFormSuccess] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    repeatPassword: '',
  });

  const [passwordValidity, setPasswordValidity] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    match: false,
  });

  const validatePassword = (password, repeatPassword) => {
    const length = password.length >= 8;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    const specialChar = /[!@#$%^&*]/.test(password);
    const match = password === repeatPassword;

    setPasswordValidity({
      length,
      uppercase,
      lowercase,
      number,
      specialChar,
      match,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'password' || name === 'repeatPassword') {
      validatePassword(
        name === 'password' ? value : formData.password,
        name === 'repeatPassword' ? value : formData.repeatPassword
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(passwordValidity).every(Boolean)) {
      // Llamar a la API para resetear la contraseña
      // Aquí podrías hacer la llamada a la API para resetear la contraseña
      // Ejemplo:
      updatePassword(user_id, formData.password).then((resetResult) => {
        if (resetResult.result) {
          setFormSuccess(true);
        } else {
          toast.error("Algo salio mal!");
        }
      });
      // Simulación de éxito
      setFormSuccess(true);
    } else {
      toast.error('El formulario tiene errores.');
    }
  };

  useEffect(() => {
    validateRequestPasswordReset(iv, content).then((vrpR) => {
      setIsValidLink(vrpR.result);
      setUser_id(vrpR.id);
    }).catch(() => {
      setIsValidLink(false); // En caso de error, marcamos el enlace como no válido
    });
  }, [iv, content]);

  return (
    <>
      {isValidLink === null ? (
        // Mostrar un mensaje de carga mientras se verifica el enlace
        <Container className="d-flex justify-content-center mt-5 min-vh-100">
          <div className="text-center">
            <h2>Verificando enlace...</h2>
          </div>
        </Container>
      ) : isValidLink ? (
        formSuccess ? (
          <Container fluid className="mt-5 justify-content-center align-items-center min-vh-100">
            <div className="login-container">
              <Row className='mt-3'>
                <Col>
                  <h1>GastoHormiga.com</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>Su contraseña ha sido restablecida exitosamente.</h3>
                  <h4>Ahora puede iniciar sesión con su nueva contraseña.</h4>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <a href='/login'>Inicio de sesión</a>
                </Col>
              </Row>
            </div>
          </Container>
        ) : (
          <Container className="d-flex justify-content-center mt-5 min-vh-100">
            <div className="reset-password-container">
              <h2 className="text-center mb-4">Restablecer Contraseña</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Nueva Contraseña</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="bi bi-lock-fill"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Ingresa tu nueva contraseña"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                  <Form.Text className="text-muted">
                    La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y un carácter especial.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formRepeatPassword" className="mb-3">
                  <Form.Label>Repite tu Nueva Contraseña</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="bi bi-lock-fill"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Repite tu nueva contraseña"
                      name="repeatPassword"
                      value={formData.repeatPassword}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <ul className="list-unstyled">
                  <li>
                    {passwordValidity.length ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos 8 caracteres
                  </li>
                  <li>
                    {passwordValidity.uppercase ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos una letra mayúscula
                  </li>
                  <li>
                    {passwordValidity.lowercase ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos una letra minúscula
                  </li>
                  <li>
                    {passwordValidity.number ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos un número
                  </li>
                  <li>
                    {passwordValidity.specialChar ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Al menos un carácter especial
                  </li>
                  <li>
                    {passwordValidity.match ? (
                      <i className="bi bi-check-circle-fill text-success"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )}{' '}
                    Las contraseñas coinciden
                  </li>
                </ul>
                <Button variant="primary" type="submit" className="w-100">
                  Restablecer Contraseña
                </Button>
              </Form>
              <Row className='mt-3'>
                <Col>
                  <a href='/login'>Inicio de sesión</a>
                </Col>
              </Row>
            </div>
          </Container>
        )
      ) : (
        // Mostrar un mensaje de error si el enlace no es válido
        <Container className="d-flex justify-content-center mt-5 min-vh-100">
          <div className="text-center">
            <Row className='mt-3'>
              <Col>
                <h1>GastoHormiga.mx</h1>
              </Col>
            </Row>
            <h2>Enlace no válido o caducado</h2>
            <a href='/login'>Volver al inicio de sesión</a>
          </div>
        </Container>
      )}
      <ToastContainer />
    </>
  );
}

export default PasswordReset;