import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getCategory, getCategoryWithSubcategories, updateCategory } from "../../services/categories";

function EditCategory() {
    const navigate = useNavigate();
    const [allCategories, setAllCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({
        id: 0,
        name: '',
        url: '',
        css_class: '',
        is_active: 0,
        name: '',
        category_id: '',
        subcategory_css_class: '',
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!form.name.trim()) {
            newErrors.name = "El nombre de la categoría es obligatorio";
            isValid = false;
        }

        if (!form.url.trim()) {
            newErrors.url = "La dirección es obligatoria";
            isValid = false;
        } else if (!form.url.startsWith("/")) {
            newErrors.url = "La dirección debe comenzar con '/'";
            isValid = false;
        }

        if (!form.subcategory_name.trim()) {
            newErrors.subcategory_name = "El nombre de la subcategoría es obligatorio";
            isValid = false;
        }

        if (!form.subcategory_category_id) {
            newErrors.subcategory_category_id = "Debe seleccionar una categoría para la subcategoría";
            isValid = false;
        }

        if (!form.subcategory_url.trim()) {
            newErrors.subcategory_url = "La dirección es obligatoria";
            isValid = false;
        } else if (!form.url.startsWith("/")) {
            newErrors.subcategory_url = "La dirección debe comenzar con '/'";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleCancel = () => {
        navigate('/ManageCategory')
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const payload = {
            category: {
                id: form.id,
                name: form.name,
                url: form.url,
                css_class: form.css_class,
                is_active: form.is_active,
            },
            subcategory: {
                id: form.subcategory_id,
                name: form.subcategory_name,
                css_class: form.subcategory_css_class,
                is_active: form.subcategory_is_active,
                category_id: form.subcategory_category_id,
                url: form.subcategory_url,
            },
        };

        console.log("Payload enviado al backend:", payload);

        updateCategory(payload).then((result) => {
            if (result.success) {
                toast.success("Registro actualizado con éxito");
                setTimeout(() => {
                    navigate('/ManageCategory');
                }, 1000);
            } else {
                toast.error("Algo salió mal");
            }
        });
    };

    useEffect(() => {
        const id = localStorage.getItem('id'); // Obtener ID desde localStorage
        if (!id) {
            toast.error("ID de categoría no encontrado");
            return;
        }

        getCategoryWithSubcategories(id).then((category) => {
            if (!category) {
                toast.error("Categoría no encontrada");
                return;
            }
            setForm({
                id: category.id,
                name: category.name,
                url: category.url,
                css_class: category.css_class,
                is_active: category.is_active ? 1 : 0,
                subcategory_name: category.subcategories?.[0]?.name || '',
                subcategory_id: category.subcategories?.[0]?.id || '',
                subcategory_css_class: category.subcategories?.[0]?.css_class || '',
                subcategory_is_active: category.subcategories?.[0]?.is_active ? 1 : 0,
                subcategory_category_id: category.id, // Almacenar ID de la categoría
                subcategory_url: category.subcategories?.[0]?.url || '',
            });
        }).catch((error) => {
            console.error('Error al cargar la categoría:', error);
            toast.error("Error al cargar la categoría");
        });

        getCategory().then((categories) => {
            setAllCategories(categories);
        }).catch((error) => {
            console.error('Error al cargar todas las categorías:', error);
            toast.error("Error al cargar todas las categorías");
        });
    }, []);

    return (
        <>
            <Container className="mt-3">
                <Form >
                    <Row className="justify-content-center">
                        <Col xl={6} >
                            <h2>Editar Categoría</h2>
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Nombre de la categoría</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formUrl" className="mb-3">
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="url"
                                    value={form.url}
                                    onChange={handleChange}
                                    isInvalid={!!errors.url}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.url}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formCssClass" className="mb-3">
                                <Form.Label>Clase</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="css_class"
                                    value={form.css_class}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formIsActive" className="mb-3">
                                <Form.Label>¿Activo?</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    label="Activo"
                                    name="is_active"
                                    checked={form.is_active === 1}
                                    onChange={(e) => setForm({ ...form, is_active: e.target.checked ? 1 : 0 })}
                                />
                            </Form.Group>
                            <>
                                {/* Línea divisoria */}
                                <hr className="my-4" />
                                <h5>Subcategorías</h5>
                                <Form.Group controlId="formSubcategoryName" className="mb-3">
                                    <Form.Label>Nombre de la subcategoría</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory_name"
                                        value={form.subcategory_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.subcategory_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subcategory_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryCategory" className="mb-3">
                                    <Form.Label>Categoría a la que pertenece</Form.Label>
                                    <Form.Select
                                        name="subcategory_category_id"
                                        value={form.subcategory_category_id}
                                        onChange={(e) => setForm({ ...form, subcategory_category_id: e.target.value })}
                                        isInvalid={!!errors.subcategory_category_id}
                                    >
                                        {allCategories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subcategory_category_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryUrl" className="mb-3">
                                    <Form.Label>Dirección</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory_url"
                                        value={form.subcategory_url}
                                        onChange={handleChange}
                                        isInvalid={!!errors.subcategory_url}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subcategory_url}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryCssClass" className="mb-3">
                                    <Form.Label>Clase</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory_css_class"
                                        value={form.subcategory_css_class}
                                        onChange={(e) =>
                                            setForm({
                                                ...form,
                                                subcategory_css_class: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                                <Form.Group controlId="formSubcategoryIsActive" className="mb-3">
                                    <Form.Label>¿Activo?</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Activo"
                                        name="is_active"
                                        checked={form.is_active === 1}
                                        onChange={(e) => setForm({ ...form, is_active: e.target.checked ? 1 : 0 })}
                                    />
                                </Form.Group>
                            </>
                            <Button variant="secondary" type="button" className="m-1" onClick={handleCancel}>
                                Cancelar
                            </Button>
                            <Button variant="primary" type="button" className="m-1" onClick={handleSubmit}>
                                Guardar cambios
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <ToastContainer />
        </>
    );
}

export default EditCategory;