import React, { useState, useEffect } from 'react';
import { Container, Form, Button, InputGroup, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { signin } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const navigate = useNavigate();
  const [siteName, setSiteName] = useState("");
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(''); // Estado para manejar errores de la API

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';

    if (savedRememberMe) {
      setFormData({
        username: savedUsername || '',
        password: savedPassword || '',
        rememberMe: savedRememberMe,
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    setErrors({ ...errors, [name]: '' });
    setApiError(''); // Limpiar el mensaje de error de la API al cambiar el valor
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.username) {
      newErrors.username = 'Por favor, ingresa tu nombre de usuario.';
      valid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Por favor, ingresa tu contraseña.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      signin(formData.username, formData.password).then((signinResult) => {
        setIsLoading(false);
        if (signinResult.result) {
          sessionStorage.setItem('token', signinResult.token);
          sessionStorage.setItem('user_id', signinResult.user_id);
          sessionStorage.setItem('user_name', signinResult.user_name);
          if (formData.rememberMe) {
            localStorage.setItem('username', formData.username);
            localStorage.setItem('password', formData.password);
            localStorage.setItem('rememberMe', formData.rememberMe);
          } else {
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
          }
          navigate(`/Home`);
        } else {
          sessionStorage.setItem('token', null);
          sessionStorage.setItem('user_id', null);
          sessionStorage.setItem('user_name', null);
          setApiError('Nombre de usuario o contraseña incorrectos.'); // Mostrar mensaje de error
        }
      }).catch(() => {
        setIsLoading(false);
        setApiError('Error en la conexión con el servidor. Inténtalo de nuevo más tarde.');
      });
    }
  };

  return (
    <Container fluid className="mt-5 justify-content-center align-items-center min-vh-100">
      <div className="login-container">
        <h3 className="text-center mb-4">ChihuaNews.com</h3>
        {apiError && <Alert variant="danger">{apiError}</Alert>} {/* Mensaje de error */}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUsername" className="mb-3">
            <Form.Label>Nombre de Usuario</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <i className="bi bi-person-fill"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Ingresa tu nombre de usuario"
                name="username"
                value={formData.username}
                onChange={handleChange}
                isInvalid={!!errors.username}
              />
            </InputGroup>
            {errors.username && <div className="error-message">{errors.username}</div>}
          </Form.Group>
          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>Contraseña</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <i className="bi bi-lock-fill"></i>
              </InputGroup.Text>
              <Form.Control
                type="password"
                placeholder="Ingresa tu contraseña"
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
            </InputGroup>
            {errors.password && <div className="error-message">{errors.password}</div>}
          </Form.Group>
          <Form.Group controlId="formRememberMe" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Recordar mis credenciales"
              name="rememberMe"
              checked={formData.rememberMe}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> Cargando...
              </>
            ) : (
              'Iniciar Sesión'
            )}
          </Button>
        </Form>
        <Row className='mt-4 mb-4'>
          <Col className='text-left'>
            <a href='/signup' >Registrarme</a>
          </Col>
        </Row>
        <Row className='mt-4 mb-4'>
          <Col className='text-left'>
            <a href='/PasswordResetRequest' >Recuperar contraseña</a>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Login;