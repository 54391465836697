import React from 'react';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Header({ expand = 'false' }) {
  const navigate = useNavigate();
  const userName = sessionStorage.getItem('user_name');
  const handleLogout = () => {
    sessionStorage.removeItem('token', null);
    sessionStorage.removeItem('user_id', null);
    sessionStorage.removeItem('user_name', null);
    navigate('/login')
  }
  return (

    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container fluid >
        <Navbar.Brand href="/">ChihuaNews.com</Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              {userName} <i className="bi bi-person-fill"></i>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Inicio</Nav.Link>
              <Nav.Link href="/AiNewsLogViewer">Ai logs</Nav.Link>                  
              <Nav.Link href="/MostRead">Lo más leído</Nav.Link>
              <Nav.Link href="/ImportNewsFromAI">Importar noticas de AI</Nav.Link>
              <Nav.Link href="/ManageSections">Administrar secciones</Nav.Link>
              <Nav.Link href="/ManageUsers">Usuarios</Nav.Link>
              <Nav.Link href='/contactus'>Administrar mensajes</Nav.Link>{/* Nuevo enlace agregado */}
              <Nav.Link href='/ManageCategory'>Categorías</Nav.Link>{/* Nuevo enlace agregado */}
              <Nav.Link onClick={handleLogout} >Cerrar sesión</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;