import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Badge, Spinner, Form } from 'react-bootstrap';
import Loading from '../../components/layout/Loading';
import { getMostRead, getMostReadByDate } from '../../services/reports';

const getMonthNames = () => {
    const today = new Date();

    // Crear formateador para obtener el nombre del mes en español
    const formatter = new Intl.DateTimeFormat('es-ES', { month: 'long' });

    // Meses actuales
    const currentMonth = formatter.format(today); // Mes actual
    const lastMonth = formatter.format(new Date(today.setMonth(today.getMonth() - 1))); // Mes pasado
    const twoMonthsAgo = formatter.format(new Date(today.setMonth(today.getMonth() - 1))); // Mes antepasado

    return { currentMonth, lastMonth, twoMonthsAgo };
};


function MostRead() { 
    let monthNames = getMonthNames();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [currentMonthNews, setCurrentMonthNews] = useState([]);
    const [previousMonthNews, setPreviousMonthNews] = useState([]);
    const [twoMonthsAgoNews, setTwoMonthsAgoNews] = useState([]);
    const [newsByDate, setNewsByDate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);    
    const [isLoadingDate, setIsLoadingDate] = useState(false);    
   const reloadReport = () => {
        setIsLoading(true);
        getMostRead().then((data) => {
            if(data)
            {
                setCurrentMonthNews(data.currentMonthNews);
                setPreviousMonthNews(data.previousMonthNews);
                setTwoMonthsAgoNews(data.twoMonthsAgoNews);
            }
        }).finally(()=> {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
   }
    useEffect(()=> {
        setIsLoading(true);
        getMostRead().then((data) => {
            if(data)
            {
                setCurrentMonthNews(data.currentMonthNews);
                setPreviousMonthNews(data.previousMonthNews);
                setTwoMonthsAgoNews(data.twoMonthsAgoNews);
            }
        }).finally(()=> {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
        setIsLoadingDate(true);
        getMostReadByDate({ date: selectedDate }).then((data) => {
            setNewsByDate(data)
        }).finally(()=>{
            setIsLoadingDate(false);
        })
    },[])
    useEffect(() => {
        setIsLoadingDate(true);
        getMostReadByDate({ date: selectedDate }).then((data) => {
            setNewsByDate(data)
        }).finally(()=>{
            setIsLoadingDate(false);
        })
    }, [selectedDate])
    return (
        <>
            <Container fluid className="mt-3">
                <Row className="align-items-center mb-5">
                    <Col xs="auto"> {/* Ajusta el botón para ocupar solo el espacio necesario */}
                        <Button 
                        onClick={reloadReport} 
                        disabled={isLoading}
                        className="btn-sm">
                          {isLoading ? <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />: "Actualizar Reporte" }  
                        </Button>
                    </Col>
                    <Col className="text-center">
                        <b className="text-danger d-block">
                            Estas listas están ordenadas de la nota más vista a la menos vista
                        </b>
                    </Col>
                </Row>
                {
                    isLoading ? 
                    <Row className="mt-3 mb-3">
                        <Col>
                            <Loading />    
                        </Col>
                    </Row>
                     : 
                    <Row>
                        <Col>
                            <Row className="mb-3" >
                                <Col>
                                    <h4>Mas leidas de {monthNames.twoMonthsAgo}</h4>                            
                                </Col>
                            </Row>
                            {
                                twoMonthsAgoNews && twoMonthsAgoNews.length > 0 ? (
                                    twoMonthsAgoNews
                                        .sort((a, b) => b.total_logs - a.total_logs) // Ordenar por `total_logs` de mayor a menor
                                        .map((item) => (
                                            <NewsItem key={item.news_id} item={item} />
                                        ))
                                ) : <NoNewsMsg />
                            }
                        </Col>
                        <Col>
                            <Row className="mb-3" >
                                <Col>
                                    <h4>Mas leidas de {monthNames.lastMonth}</h4>                            
                                </Col>
                            </Row>
                            {
                                previousMonthNews && previousMonthNews.length > 0 ? (
                                    previousMonthNews
                                        .sort((a, b) => b.total_logs - a.total_logs) // Ordenar por `total_logs` de mayor a menor
                                        .map((item) => (
                                            <NewsItem key={item.news_id} item={item} />
                                        ))
                                ) : <NoNewsMsg />
                            }
                        </Col>
                        <Col>
                            <Row className="mb-3" >
                                <Col>
                                    <h4>Mas leidas de {monthNames.currentMonth}</h4>                            
                                </Col>
                            </Row>
                            {
                                currentMonthNews && currentMonthNews.length > 0 ? (
                                    currentMonthNews
                                        .sort((a, b) => b.total_logs - a.total_logs) // Ordenar por `total_logs` de mayor a menor
                                        .map((item) => (
                                            <NewsItem key={item.news_id} item={item} />
                                        ))
                                ) : <NoNewsMsg />
                            }
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                <Form.Group controlId="filterDate">
                                    <Form.Label>Selecciona una fecha:</Form.Label>
                                    <Form.Control
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    required
                                    />
                                </Form.Group>
                                </Col>
                            </Row>
                            {
                                isLoadingDate ? 
                                <Row className="mt-4">
                                    <Col>
                                        <Loading />
                                    </Col>
                                </Row>
                                 :
                                newsByDate && newsByDate.length > 0 ? (
                                    newsByDate
                                        .sort((a, b) => b.total_logs - a.total_logs) // Ordenar por `total_logs` de mayor a menor
                                        .map((item) => (
                                            <NewsItem key={item.news_id} item={item} />
                                        ))
                                ) : <NoNewsMsg />
                            }
                        </Col>
                    </Row>
                }
                
            </Container>
        </>
    );
}

const NoNewsMsg = () => {
    return (
        <Row>
            <Col>
                <h5 className="text-secondary" >Aún no hay datos de este mes</h5>
            </Col>
        </Row>
    )
}

const NewsItem = ({ item }) => {
    const handleRedirect = (url) => {
        window.open(url, '_blank');
    };
    return (
        <>
            <Card className="mt-2 mb-2" >       
                {
                    item.imageUrl ?
                    <Card.Img variant="top" src={item.imageUrl} />:
                    null
                }  
                
                <Card.Body>
                    <h4>
                        Conteo de vistas <Badge bg="success">{item.total_logs}</Badge>
                    </h4>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>
                        {item.short_description}
                    </Card.Text>   
                    <b className="text-muted" >{item.shortDate}</b>
                    <Button variant="link" onClick={()=> handleRedirect(item.fullUrl)} className="btn btn-primary">
                        Ver nota
                    </Button>                 
                </Card.Body>
              
            </Card>
        </>
    )
}


export default MostRead;
